import React, { Component } from "react";
import lawsociety from "../../images/prolink-law-society-logo.png";
import shinwoo from "../../images/prolink-shinwoo-logo.png";
import criminal_law from "../../images/criminal_law_specialist_logo.png";
import cpa from "../../images/prolink-cpa-logo.png";
import ipa from "../../images/ipa-logo.png";
// import taxagent from "../../images/landscape_colour_26072955.png";
import taxagent from "../../images/taxagent-logo-white.png";

export class BannerSection extends Component {
  render() {
    return (
      <div>
        <section
          className="elementor-element elementor-element-3a8c5ca elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle elementor-section elementor-top-section"
          data-id="3a8c5ca"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
        >
          <div className="elementor-background-overlay"></div>
          <div className="prolink-banner-logos-container elementor-container elementor-column-gap-default">
            <div className="elementor-row">
              <div
                className="prolink-banner-logos-column elementor-element elementor-element-457a02d elementor-column elementor-col-100 elementor-top-column"
                data-id="457a02d"
                data-element_type="column"
                data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
              >
                <div className="elementor-column-wrap">
                  <div className="prolink-inline elementor-widget-wrap">
                    {/* <div className="prolink-banner-logos-left">
                      <img src={shinwoo} alt=""></img>
                    </div> */}
                    <div className="prolink-banner-logos-right">
                      <img className="prolink-banner-logos-right-cpa" src={cpa} alt=""></img>
                      <img className="prolink-banner-logos-right-ipa" src={ipa} alt=""></img>
                      <img className="prolink-banner-logos-right-taxagent" src={taxagent} alt=""></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="prolink-banner-container elementor-container elementor-column-gap-default">
            <div className="elementor-row">
              <div
                className="elementor-element elementor-element-bae217d elementor-column elementor-col-50 elementor-top-column"
                data-id="bae217d"
                data-element_type="column"
                data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_infinite":"false"}'
              >
                <div className="elementor-column-wrap elementor-element-populated">
                  <div className="elementor-widget-wrap prolink-left-padding">
                    <div
                      className="elementor-element elementor-element-9cec2ce elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                      data-id="9cec2ce"
                      data-element_type="widget"
                      data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h1 className="elementor-heading-title elementor-size-default prolink-main-banner-title">
                          PROLINK
                          <br />
                          PARTNERS
                        </h1>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-6d49591 elementor-widget elementor-widget-text-editor"
                      data-id="6d49591"
                      data-element_type="widget"
                      data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_duration":1000,"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-text-editor elementor-clearfix">
                          <div className="lqd-lines split-unit lqd-unit-animation-done prolink-main-banner-subtitle">
                            Prolink Parnters pursues Professionalism and
                            Integrity when we provide our services to clients.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-42ab336 elementor-align-left elementor-mobile-align-center elementor-widget elementor-widget-button"
                      data-id="42ab336"
                      data-element_type="widget"
                      data-settings='{"avante_ext_is_smoove":"true","avante_ext_smoove_disable":"769","avante_ext_smoove_rotatex":{"unit":"px","size":-90,"sizes":[]},"avante_ext_smoove_translatey":{"unit":"px","size":40,"sizes":[]},"avante_ext_smoove_translatez":{"unit":"px","size":-140,"sizes":[]},"avante_ext_smoove_duration":1000,"avante_ext_is_scrollme":"false","avante_ext_smoove_scalex":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_scaley":{"unit":"px","size":1,"sizes":[]},"avante_ext_smoove_rotatey":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_rotatez":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_translatex":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewx":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_skewy":{"unit":"px","size":0,"sizes":[]},"avante_ext_smoove_perspective":{"unit":"px","size":1000,"sizes":[]},"avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <a
                            href="/home#services"
                            className="prolink-banner-button elementor-button-link elementor-button elementor-size-md prolink-button"
                            role="button"
                          >
                            <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">
                                Our Services
                              </span>
                            </span>
                          </a>
                          <a
                            href="/home#contact-us"
                            className="prolink-banner-button elementor-button-link elementor-button elementor-size-md transparent-button"
                            role="button"
                          >
                            <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">
                                Contact Us
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-457a02d elementor-column elementor-col-50 elementor-top-column"
                data-id="457a02d"
                data-element_type="column"
                data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
              >
                <div className="elementor-column-wrap">
                  <div className="elementor-widget-wrap"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
